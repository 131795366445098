import React from 'react'

import { default as Layout } from 'layouts/AuthenticatedPage'

class Body extends React.Component {
  render() {
    console.log(this.props)
    return <div>Test</div>
  }
}

export default props => {
  return (
    <Layout {...props}>
      <Body />
    </Layout>
  )
}
